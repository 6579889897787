<template>
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box"
          >
            <h2>Register Here!</h2>
            <h3>October 22nd, 2022 At Boerner Botanical Gardens</h3>
          </div>
        </div>
        <div id="guestRegBox" class="d-flex justify-content-center" v-bind:class="{'centerAligning': !finished}">
          <Transition name="bounce">
            <div v-if="error == true" class="row">
              <div
            class="col-md-8 col-md-offset-2 text-center fh5co-heading guestFinish"
          >
            <h2 style="color:red">Opps, something went wrong</h2>
            <p>We couldn't find that guest, or something else when wrong. Try reloading the page, or contact us with the details below. We would be love to personally help you with this.</p>
       
            <h3 style="font-size: 2em;">Contact us at bgandjakewedding@gmail.com</h3>
            <h3>Or by phone or text at {{ numb }}</h3>

          </div>
            </div>
            <div v-else-if="finished == true" class="row">
              <div
            class="col-md-8 col-md-offset-2 text-center fh5co-heading guestFinish"
          >
            <h2>Thank You!</h2>
       
            <h3 style="font-size: 2em;">We are registered at <a href="https://sarvthedate.com/amazon">Amazon</a>, <a href="https://sarvthedate.com/etsy">Etsy</a>, and <a href="https://sarvthedate.com/honeyfund">Honey Fund</a>.</h3>
            <h4>Hotel Accommodations</h4>
            <p>For your convenience rooms have been reserved. Please reference the Sarvela Wedding when booking.</p>
            <p>Hampton Inn and Suites
            <br>8200 w Greenfield Avenue
            <br>West Allis WI 53214</p>
            <p><a href="tel:414-436-2300">414-436-2300</a></p>
            <p>For the discounted rate, reserve by September 23rd</p>
          </div>
            </div>
          <FormKit
            v-else-if="guests.length == 0"
            style="display: flex; align-items: flex-end"
            type="form"
            :value="{
              Name: '',
              zip: '',
              email: '',
            }"
            submit-label="Enter"
            @submit="save"
          >
            <FormKit
              type="text"
              v-model="inputName"
              name="Name"
              label="first and last name"
              placeholder="Bridget Griffin"
              validation="required"
            />
            <FormKit
              type="text"
              v-model="inputZip"
              name="zip"
              label="Zip Code"
              placeholder="53226"
              validation="required"
            />
            <FormKit
              type="text"
              name="email"
              v-model="inputEmail"
              label="Your email"
              placeholder="email@email.com"
              validation="required|email"
            />
          </FormKit> 
          <div v-else-if="guests.length > 0" class="reg-cards-list guestList">
          <button v-on:click="UpdateData()" class="subbutton" style="bottom:0px;left:calc(20px + 1rem)">Submit {{guests.length > 1 ? "All" : ""}}</button>
          <div v-for="guest, index in guests" :key="guest[0]" class="guestCard" :class="{ grayOut: !guest[6] }">
            <h1>Guest {{index + 1}}</h1>
            <FormKit 
              style=""
              type="form"
              :value="{
                Id:guest[0],
                attending: guest[6],
                food: guest[7],
                allergens: guest[8],
              }"
            >
              <FormKit
                v-model="guest[6]"
                type="checkbox"
                label="Attending"
                name="attending"
              />
              <FormKit
                type="text"
                name="Name"
                v-model="guest[9]"
                label="Name of Guest"
                placeholder="Joe"
                validation="required"
              />
              <FormKit
                name="food"
                v-model="guest[7]"
                type="radio"
                label="Food Options"
                :options="{
                  chicken: 'Grilled Chicken Breast',
                  ribs: 'Braised Beef Short Ribs',
                  vegi: 'Vegetarian'
                }"
                help="BG recommends the Short Ribs"
              />
              <div>{{ dinneroption[guest[7]] }}</div>
              <FormKit
                type="text"
                name="allergens"
                v-model="guest[8]"
                label="any relevent allergies"
                placeholder="gluten, shellfish"
              />
              <FormKit
                type="text"
                v-model="guest[11]"
                name="notes"
                label="anything else we should know"
              />
            </FormKit>
          </div>
          </div>
          </Transition>
        </div>
      </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  data() {
		return {
			count: 0,
			guests: [],
			dinneroption: {
				chicken: "A boneless, skinless breast of chicken grilled and laced with one of our specialty sauces, stonemill dijon creme",
				ribs: "Tender, flavorful and hearty. These boneless ribs are braised in red wine, carrots, celery, onions. veal jus, and herbs for nearly 12 hours, with a chimichurri sauce",
				vegetarian: "The chef's vegitarian option."
			},
      inputName:"",
      inputZip:"",
      inputEmail:"",
      data:{},
      finished:false,
      error:false,
      numb:"414-708-7850",
		};
	},
	methods: {
		save() {
			console.log("guest check");
      this.FetchData();
		},
		increment: () => {
			// BAD: no `this` access here!
		},
    TestBut(){
      this.inputEmail = "usatonme@gmail.com";
      this.inputZip = "53226";
      this.inputName = "testing joe";
      this.FetchData();
    },
		FetchData() {
			// I prefer to use fetch
			// you can use use axios as an alternative
      const datasend = {"name":this.inputName, "zip":this.inputZip, "email":this.inputEmail}
      console.log(datasend);
			return fetch("/getguest", {
				method: "post",
        body: JSON.stringify(datasend),
				headers: {
					"content-type": "application/json",
				},
			})
				.then((res) => {
					// a non-200 response code
					if (!res.ok) {
						// create error instance with HTTP status text
            this.error = true;
						const error = new Error(res.statusText);
						error.json = res.json();
						throw error;
					}

					return res.json();
				})
				.then((json) => {
					// set the response data
          if (json.error != undefined) this.error = true;
          console.log("yolo");
          console.log(json);
					this.data = json
          if (json.guests != undefined) json.guests.forEach(function(ele){
            ele[9] = ele[2] + " " + ele[3];
            ele[5] = true;
            ele[10] = this.inputEmail;
            this.guests.push(ele)
          }, this);
				})
				.catch((err) => {
					console.log(err)
					// error.value = err;
					// // In case a custom JSON error response was provided
					// if (err.json) {
					// 	return err.json.then((json) => {
					// 		// set the JSON response message
					// 		error.value.message = json.message;
					// 	});
					// }
				})
				.then(() => {
          console.log("done")
				});
		},
    UpdateData () {
			// I prefer to use fetch
			// you can use use axios as an alternative
      const datasend = {guests:this.guests};
      console.log(datasend);
			return fetch("/updateguest", {
				method: "post",
        body: JSON.stringify(datasend),
				headers: {
					"content-type": "application/json",
				},
			})
				.then((res) => {
					// a non-200 response code
					if (!res.ok) {
						// create error instance with HTTP status text
            this.error = true;
						const error = new Error(res.statusText);
						error.json = res.json();
						throw error;
					}

					return res.json();
				})
				.then((json) => {
					// set the response data
          console.log("yolo");
          console.log(json);
					this.data = json
          this.finished = true;
          document.getElementById("guestRegBox").scrollIntoView(true)
          ThrowConf();
          ThrowConf();
          ThrowConf();
          ThrowConf();
          ThrowConf();
          ThrowConf();
          ThrowConf();
          ThrowConf();
          ThrowConf();
				})
				.catch((err) => {
					console.log(err)
					// error.value = err;
					// // In case a custom JSON error response was provided
					// if (err.json) {
					// 	return err.json.then((json) => {
					// 		// set the JSON response message
					// 		error.value.message = json.message;
					// 	});
					// }
				})
				.then(() => {
          console.log("done")
				});
		},
	}
};
</script>
